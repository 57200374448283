import React from 'react';

import logo from '../../assets/img/logo.svg';
import xIcon from '../../assets/img/x-icon.svg';
import tgIcon from '../../assets/img/telegram-icon.svg';
import dexIcon from '../../assets/img/dexscreener-icon.svg';

import heroImg from '../../assets/img/hero-img.svg';
import heroImgHover from '../../assets/img/hero-img-hover.svg';

import loreBeforeImg from '../../assets/img/lore-before-bg.svg';
import loreImg from '../../assets/img/lore-img.png';

import tokenomicsBg from '../../assets/img/tokenomics-bg.svg';

const Home = ({pageScrollPosition}) => (
    <>
        <header>
            <div className="links-wrap">
                <a href="#hero">About</a>
                <a href="#lore">Lore</a>
                <a href="#tokenomics">Tocumnomics</a>
            </div>
            <div className="socials-wrap">
                <a href="https://x.com/get_sentralized" target="_blank" rel="noreferrer">
                    <img src={xIcon} alt="X"/>
                </a>
                <a href="https://t.me/sentralizedexchange" target="_blank" rel="noreferrer">
                    <img src={tgIcon} alt="Telegram"/>
                </a>
                <a href="#" className="disabled">
                    <img src={dexIcon} alt="Dexscreener"/>
                </a>
            </div>
            <div className="logo-wrap">
                <img src={logo} alt="Logo"/>
            </div>
        </header>
        <main>
            <section id="hero" className="hero-section">
                <div className="img-wrap">
                    <img src={heroImg} alt="Hero"/>
                    <img src={heroImgHover} alt="Hero hover" className="img-hover"/>
                </div>
                <div className="text-wrap">
                    <div className="headline-wrap">
                        <h1>Sentralized<br/> Exchange</h1>
                        <p>Sentralized Exchange, with <span>[REDACTED]</span> as its native currency, is the universal
                            memecoin trading platform in a post-apocalyptic world where only memecoin connoisseurs
                            survive.</p>
                    </div>
                    <div className="address-wrap">
                        <p>Address:</p>
                        <div className="card-wrap">
                            <p>CUMMING SOON</p>
                        </div>
                    </div>
                </div>
                <div className="before-wrap">
                    <img src={loreBeforeImg} alt="Lore before element"/>
                </div>
            </section>
            <section id="lore" className="lore-section">
                <div className="text-wrap">
                    <h2>Lore</h2>
                    <div className="info-wrap">
                        <p>In an alternative universe where a catastrophic apocalypse wiped out most of humanity, only
                            the crypto traders, CT influencers, and memecoin developers managed to survive. Over
                            centuries, these survivors evolved into a new species known as the Penosaur. This resilient
                            species adapted to a world where traditional necessities like food and water were obsolete.
                            Instead, the Penosaur thrived on their innate ability to trade meme coins.<br/><br/>
                            Central to their existence is the Sentralized Exchange, a universal crypto trading platform
                            where all transactions occur. The Penosaur's lifeblood is [REDACTED], their native currency, which
                            fuels their economy and sustains their digital lives. Every day, they engage in frenetic
                            trading activities, their society driven by the rise and fall of meme coin values.<br/><br/>
                            In this post-apocalyptic world, the Penosaur embody the ultimate fusion of survival and
                            cryptocurrency, a testament to the enduring spirit of trade and speculation.</p>
                    </div>
                </div>
                <div className="img-wrap">
                    <img src={loreImg} alt="Lore"/>
                </div>
            </section>
            <section id="tokenomics" className="tokenomics-section">
                <div className="headline-wrap">
                    <h2>Tocumnomics</h2>
                </div>
                <div className="items-wrap">
                    <div className="bg">
                        <img src={tokenomicsBg} alt="Tocumnomics"/>
                    </div>
                    <div className="item">
                        <h3>TOTAL SUPPLY<br/> 69 BILLION</h3>
                    </div>
                    <div className="item">
                        <h3>stealth<br/> launch</h3>
                    </div>
                    <div className="item">
                        <h3>LP BURNED<br/> AT LAUNCH</h3>
                    </div>
                    <div className="item">
                        <h3>Contract<br/> renounced</h3>
                    </div>
                </div>
            </section>
        </main>
        <footer>
            <p>2024 © Sentralized Exchange</p>
        </footer>
    </>
);

export default Home;
