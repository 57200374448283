import {useState, useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import {useLocation} from 'react-router-dom';

import Home from './components/Home';

const App = () => {
    let location = useLocation();
    const {pathname} = location;
    const [pageScrollPosition, setPageScrollPosition] = useState(0);

    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = "manual";
        }

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [pathname]);

    const handleScroll = () => {
        const position = window.scrollY;
        setPageScrollPosition(Math.round(position));

        Array.from(document.querySelectorAll('.animation')).forEach((ae) => {
            const {bottom, height} = ae.getBoundingClientRect();
            if (bottom - (height / 2) - window.innerHeight < 0) {
                ae.classList.add('animation-active');
            } else {
                ae.classList.remove('animation-active');
            }
        })
    };

    const appHeightHandle = () => {
        document.querySelector('body').style.setProperty('--app-height', window.innerHeight + 'px');
    }

    window.addEventListener('resize', () => {
        if (window.innerWidth < 1200) {
            appHeightHandle();
        }
    });

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true
        });
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    appHeightHandle();

    return (
        <Routes>
            <Route path="*" element={<Home pageScrollPosition={pageScrollPosition}/>}/>
        </Routes>
    )
};

export default App;
